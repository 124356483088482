import { getAssetUrl } from "@/utils";
import { Box, Card, SimpleGrid, Text } from "@mantine/core";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";

const services = [
  {
    name: "Japan Wireless",
    key: "japan_wireless",
    link: "https://www.japan-wireless.com/",
  },
  {
    name: "Japan Wireless eSIM",
    key: "jw_esim",
    link: "https://www.japan-wireless.com/esim",
  },
  {
    name: "Japan Wireless Voice SIM",
    key: "jw_voicesim",
    link: "https://www.mobal.com/japan-sim-card/?source=3443",
  },
  {
    name: "El Monte RV Japan",
    key: "elmonte",
    link: "https://elmonterv-japan.com/",
  },
  {
    name: "Airport Taxi",
    key: "airport_taxi",
    link: "https://www.airport-taxi.tokyo/",
  },
  {
    name: "Clinic Nearme",
    key: "clinic_nearme",
    link: "https://clinicnearme.jp/",
  },
  {
    name: "Living Japan",
    key: "living_japan",
    link: "https://www.livingjapan.com/",
  },
  {
    name: "Japan Web Magazine",
    key: "japan_web_magazine",
    link: "https://jw-webmagazine.com/",
  },
];

export default function GroupService(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box>
      <Text>{t("top:group_services.description")}</Text>
      <Card className="border border-solid border-red-1 mt-3 rounded-md px-2 md:px-4 overflow-visible">
        <SimpleGrid
          cols={{ base: 2, md: 4 }}
          spacing={{ base: "sm", md: "md" }}
          className="w-full md:w-4/5 mx-auto"
        >
          {services.map((item, key) => (
            <Box
              key={key}
              className="flex flex-col hover:shadow-lg hover:shadow-blue-5 transition-shadow duration-300"
              component={Link}
              target="_blank"
              href={`${item.link}${
                item.key !== "jw_voicesim"
                  ? "?utm_source=japanbullettrain&utm_medium=referral"
                  : ""
              }`}
            >
              <Box className="border border-solid border-blue-9 flex justify-center items-center">
                <Image
                  src={getAssetUrl(`/services/${item.key}.webp`)}
                  alt={item.name}
                  width={200}
                  height={70}
                  className="w-[135px] md:w-[200px] h-[47px] md:h-[70px]"
                />
              </Box>
              <Box className="bg-blue-9 py-1.5 px-0.5 flex-1 flex flex-col items-center justify-center">
                <Text className="text-center !text-white text-xs md:text-lg font-bold">
                  {item.name}
                </Text>
                <Text className="text-center !text-white text-xxs md:text-sm">
                  {t(`top:group_services.items.${item.key}`)}
                </Text>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Card>
    </Box>
  );
}
